import { graphql, useStaticQuery } from "gatsby";

const useSeo = () => {
  const data = useStaticQuery(graphql`
    query SeoQuery {
      sanitySeo {
        title
        description
        description_gallery
        description_events
        description_contact
        og_image {
          alt
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `);

  return data.sanitySeo;
};

export default useSeo;
