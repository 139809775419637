import PropTypes from "prop-types";
import React from "react";

import "twin.macro";

import { Highlight } from "@components/shared";

const Quote = ({ ...props }) => (
  <blockquote aria-label="Nasze motto" tw="lg:leading-normal" {...props}>
    Można nie jeść w ogóle,
    <br />
    ale nie można jeść źle.
    <Highlight tw="lg:mt-5">Salvador Dalí</Highlight>
  </blockquote>
);

Quote.propTypes = {
  big: PropTypes.bool,
};

export default Quote;
