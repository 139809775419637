import tw from "twin.macro";

const Container = tw.div`
  w-container max-w-screen-xxl
  mx-auto
  to-lg:text-center
`;

Container.Area = tw.div`
  px-5 lg:px-20
  py-32
  bg-primary-pale
  text-primary
  text-center
`;

export default Container;
