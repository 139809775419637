import React from "react";

import "twin.macro";

import Img from "gatsby-image/withIEPolyfill";

const Image = ({ ...props }) => (
  <Img
    objectFit="cover"
    objectPosition="50% 50%"
    tw="w-full min-h-120 h-120 shadow-inner"
    {...props}
  />
);

export default Image;
