import React from "react";
import PropTypes from "prop-types";

import "twin.macro";

import { ButtonText } from "@components/shared";

const SocialText = ButtonText.withComponent("span");

const SocialIcon = ({ text, href, icon: Icon, ...props }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    tw="inline-flex items-center justify-between hover:opacity-75"
    {...props}
  >
    <Icon tw="text-xl mr-2" />
    {text && <SocialText as="span">{text}</SocialText>}
  </a>
);

SocialIcon.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
};

export default SocialIcon;
