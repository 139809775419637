import { graphql, useStaticQuery } from "gatsby";

const useGlobal = () => {
  const data = useStaticQuery(graphql`
    query GlobalQuery {
      sanityGlobal {
        newsletter {
          title
          button
          placeholder
        }
        socials {
          facebook
          instagram
        }
        cta
        copyright
      }
    }
  `);

  return data.sanityGlobal;
};

export default useGlobal;
