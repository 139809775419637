import React from "react";

import BlockContent from "@sanity/block-content-to-react";
import tw, { styled } from "twin.macro";

import Img from "gatsby-image/withIEPolyfill";

import { useImageAsset } from "@queries";

const Textbox = styled(BlockContent)`
  ${tw`w-full`}

  & p {
    ${tw`mb-3 tracking-wider leading-8`}
    ${({ center }) => (center ? tw`text-center` : tw`text-justify`)}
  }

  & li {
    ${tw`mb-1 leading-8`}
  }
`;

const ImgType = ({ node }) => {
  const [imageAsset] = useImageAsset(node.asset._ref);
  return (
    <Img
      fluid={imageAsset.node.fluid}
      alt={node.alt}
      objectFit="cover"
      objectPosition="center top"
      tw="shadow-inner my-16"
      style={{
        maxHeight: "800px",
      }}
    />
  );
};

const Highlight = tw.strong`
  font-main
  font-bold
  uppercase
  text-primary
  text-sm
  tracking-highlight
`;

const Bold = tw.b`font-bold`;

Textbox.defaultProps = {
  renderContainerOnSingleChild: true,
  serializers: {
    marks: {
      highlight: Highlight,
      b: Bold,
    },
    types: {
      img: ImgType,
    },
  },
};

export default Textbox;
