import React from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import PropTypes from "prop-types";

import "twin.macro";

const ErrorMsg = ({ msg }) => (
  <div tw="flex items-center to-sm:flex-col mx-auto text-primary pb-24">
    <AiOutlineExclamationCircle tw="inline-block text-2xl to-sm:mb-2 sm:mr-2" />
    <span tw="text-xl inline-block">{msg}</span>
  </div>
);

ErrorMsg.propTypes = {
  msg: PropTypes.string.isRequired,
};

export default ErrorMsg;
