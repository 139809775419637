import tw from "twin.macro";

const Highlight = tw.strong`
  font-bold
  uppercase
  text-primary
  text-sm
  tracking-highlight
`;

export default Highlight;
