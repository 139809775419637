import { graphql, useStaticQuery } from "gatsby";

const useImageAsset = (assetId) => {
  const data = useStaticQuery(graphql`
    query ImageAssetQuery {
      allSanityImageAsset {
        edges {
          node {
            _id
            fluid(maxWidth: 1720) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `);

  return data.allSanityImageAsset.edges.filter(
    ({ node }) => node._id === assetId
  );
};

export default useImageAsset;
