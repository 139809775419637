import tw, { styled } from "twin.macro";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const ButtonText = styled(AnchorLink)`
  ${tw`
    uppercase
    font-black
    tracking-button
    text-base
    text-xs
  `}

  & svg {
    ${tw`ml-2 text-lg`}
  }
`;

export default ButtonText;
