import { graphql, useStaticQuery } from "gatsby";

const useOfferList = () => {
  const data = useStaticQuery(graphql`
    query OfferListQuery {
      allSanityOffer(sort: { order: ASC, fields: _createdAt }) {
        edges {
          node {
            title
            image {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
              alt
            }
            _rawDescription
          }
        }
      }
    }
  `);

  return data.allSanityOffer.edges.map((edge) => edge.node);
};

export default useOfferList;
