import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

import "twin.macro";

import { useLogoImg } from "@queries";

import logoSVG from "@images/logo.svg";

const Logo = () => {
  const cebula = useLogoImg();

  return (
    <Link to="/" aria-label="Przejdź na stronę główną" tw="flex items-end">
      <img
        src={logoSVG}
        tw="block w-auto h-10 xl:h-12 fill-current mr-3"
        aria-hidden="true"
      />
      <Img
        tw="w-16 to-lg:w-12 h-auto"
        fluid={cebula.fluid}
        objectFit="cover"
        objectPosition="50% 50%"
        aria-hidden="true"
      />
    </Link>
  );
};

export default Logo;
