import React from "react";
import { FaInstagram, FaFacebookSquare } from "react-icons/fa";

import "twin.macro";

import { useGlobal } from "@queries";

import SocialIcon from "./SocialIcon";

const Socials = ({ ...props }) => {
  const { socials } = useGlobal();

  return (
    <div {...props}>
      <SocialIcon
        text="Facebook"
        href={socials.facebook}
        icon={FaFacebookSquare}
        tw="xs:mr-5 to-xs:mb-2 to-xs:flex items-center justify-center"
      />
      <SocialIcon
        text="Instagram"
        href={socials.instagram}
        icon={FaInstagram}
        tw="to-xs:flex items-center justify-center"
      />
    </div>
  );
};

export default Socials;
