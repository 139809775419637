import tw from "twin.macro";
import { ButtonText } from "@components/shared";

const Button = tw(ButtonText)`
  bg-primary
  text-white

  inline-flex
  items-center
  px-4
  py-2
  shadow-button
  rounded-t-top
  rounded-b-bottom

  transition-transform duration-100 ease-in
  hocus:transform hover:scale-102
  focus:outline-none
`;

export default Button;
